/* --- CLUSTERS --- */
.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition:
    -webkit-transform 0.3s ease-out,
    opacity 0.3s ease-in;
  -moz-transition:
    -moz-transform 0.3s ease-out,
    opacity 0.3s ease-in;
  -o-transition:
    -o-transform 0.3s ease-out,
    opacity 0.3s ease-in;
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition:
    -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition:
    -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition:
    -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition:
    stroke-dashoffset 0.3s ease-out,
    stroke-opacity 0.3s ease-in;
}

.marker-cluster-small {
  background-color: rgba(36, 158, 107, 0.6);
}
.marker-cluster-small div {
  background-color: rgb(36, 158, 107);
}

.marker-cluster-medium {
  background-color: rgba(255, 181, 73, 0.6);
}
.marker-cluster-medium div {
  background-color: rgb(255, 181, 73);
}

.marker-cluster-large {
  background-color: rgba(250, 146, 112, 0.6);
}
.marker-cluster-large div {
  background-color: rgb(250, 146, 112);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
}

.marker-cluster span {
  line-height: 30px;
  font-size: 0.9rem;
  font-family: Apercu-bold;
}

/* --- FULLSCREEN --- */
/* Do not combine these two rules; IE will break. */
.leaflet-container:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}
.leaflet-container.leaflet-fullscreen-on {
  width: 100% !important;
  height: 100% !important;
}

.leaflet-pseudo-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99999;
}
