@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

/* a few components disable body scroll when open / applying overlay */
.cfa-disable-body-scroll {
  overflow: hidden;
}

@font-face {
  font-family: 'Apercu Std';
  src: url('./assets/fonts/apercu-extralight.otf');
  font-weight: 200;
}

@font-face {
  font-family: 'Apercu Std';
  src: url('./assets/fonts/apercu-regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Apercu Std';
  src: url('./assets/fonts/apercu-medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Apercu Std';
  src: url('./assets/fonts/apercu-bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Apercu Std';
  src: url('./assets/fonts/apercu-extrabold.otf');
  font-weight: 800;
}

@font-face {
  font-family: 'Caecilia';
  src: url('./assets/fonts/caecilia-heavy.otf');
  font-weight: 900;
}

* {
  font-family: 'Apercu Std';
  font-weight: 400;
}

svg {
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
p {
  color: #5b6770;
}

h1,
h2,
h3 {
  font-family: 'Apercu Std';
  font-weight: 700;
}

label,
h4,
h5,
h6,
p,
a {
  font-family: 'Apercu Std';
  font-weight: 500;
}

.button {
  font-family: 'Apercu Std' !important;
  font-weight: 700;
}

button:active,
button:focus,
button:focus:active {
  background-image: none !important;
  outline: 0;
  box-shadow: none !important;
}

/* Style App scrollbar */
::-webkit-scrollbar {
  height: 0.25rem;
  width: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #80878c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5b6770;
}

/* CFA LINKS STYLE */
a {
  color: #004f71 !important;
  font-weight: 500;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Overrides for CFA React Components */
.cfa-dropdown-wrapper,
.cfa-textfield-wrapper,
.cfa-textfield {
  max-width: 100%;
}

.cfa-dropdown-item {
  height: min-content !important;
}

.cfa-dropdown-display > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: left;
  padding-right: 0.5rem;
}

.cfa-dropdown-display > * small {
  display: none;
}

.cfa-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

:disabled {
  cursor: not-allowed !important;
}

/* Overrides for Leaflet maps */
.leaflet-popup-content-wrapper {
  box-shadow: none;
}

.leaflet-popup-content {
  margin: 0;
  width: max-content !important;
}

.leaflet-popup-content p {
  margin: 0;
}
